@use "sass:map";
@use "../functions";
@use "../variables";

.pricebook {

  &__head {
    display: flex;
    gap: 2.25rem;

    > p {
      padding-top: 1rem;
    }
  }
  &__body {
    max-width: 100%;
    .tooltip {
      &.dark {
        background: map.get(variables.$colours, grey-subtle-500);
        border: map.get(variables.$colours, grey-bold-100);
        border-radius: 3px;
        line-height: 32px;
        width: 32px;
        aspect-ratio: 1/1;
        font-weight: bold;
      }
    }

    .data-table__table thead th {
      &:not(:nth-child(6)) {
        min-width: 100px;
      }

      &.width-200 {
        min-width: 200px;
      }

      &.width-300 {
        min-width: 300px;
      }
    }
  }
}
