.loading-spinner {
  animation: bblFadInOut 1s infinite ease-in-out;
  animation-fill-mode: both;
  background: currentColor;
  border-radius: 50%;
  display: inline-block;
  width: 0.75em;
  height: 0.75em;

  @keyframes bblFadInOut {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 0; }
  }
}