@use "sass:map";
@use "../functions";
@use "../variables";

.admonition {
  --admonition-base-colour: #{map.get(variables.$colours, "grey-subtle-500")};
  --admonition-secondary-colour: #{map.get(variables.$colours, "grey-bold-500")};

  max-width: 60ch;
  margin: 0 auto;
  position: relative;
  padding: (variables.$base-line-height * 0.5) variables.$base-line-height;
  border: functions.px-to-rem(2px) solid var(--admonition-base-colour);
  border-left-width: variables.$base-line-height * 3;
  border-radius: variables.$base-line-height * 0.5;
  min-height: variables.$base-line-height * 2;


  svg {
    color: var(--admonition-secondary-colour);
    width: variables.$base-line-height * 2;
    height: variables.$base-line-height * 2;
    position: absolute;
    left: variables.$base-line-height * -2.5;
    top: variables.$base-line-height * 0.5;
  }

  &--full-width {
    max-width: 100%;
  }

  &--success {
    --admonition-base-colour: #{map.get(variables.$colours, "green-medium-500")};
    --admonition-secondary-colour: #{map.get(variables.$colours, "white")};
  }

  &--fail {
    --admonition-base-colour: #{map.get(variables.$colours, "red-medium-500")};
    --admonition-secondary-colour: #{map.get(variables.$colours, "white")};
  }
}
