@import "../functions";
@import "../variables";

.order-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $base-line-height * 2;
  margin: 0 0 $base-line-height;

  p {
    margin: 0;

    & + p {
      margin-top: $base-line-height * 0.5;
    }
  }
}

.order-details-table .data-table__table tr {
  th:not(.centered-text) {
    text-align: left;
  }

  td {
    vertical-align: top;
  }

  &.order-details-table__rule td {
    border-top: px-to-rem(1px) solid map-get($colours, "grey-subtle-500");
  }
}
