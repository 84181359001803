@use "sass:map";
@use "sass:math";
@use "functions";
@use "variables";

a {
  color: map.get(variables.$colours, "blue-darkest-500");
  font-weight: bold;

  &:focus, &:hover {
    color: map.get(variables.$colours, "blue-darkest-900");
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: #{map.get(variables.$font-families, base)};
  line-height: variables.$base-line-height;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
  height: 100%;

  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

hr {
  border: none;
  border-top: functions.px-to-rem(2px) solid map.get(variables.$colours, "grey-bold-100");

  &[aria-hidden="true"] {
    display: none;
  }
}

h1 {
  font-size: percentage(math.div(36px, variables.$base-font-size));
  line-height: variables.$base-line-height * 1.5;
  margin: 0;
}

h2 {
  font-size: percentage(math.div(30px, variables.$base-font-size));
  line-height: variables.$base-line-height * 1.5;
  margin: 0;
}

h3 {
  font-size: percentage(math.div(24px, variables.$base-font-size));
  line-height: variables.$base-line-height * 1.25;
  margin: 0;
}

textarea,
input[type="text"],
input[type="number"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="file"] {
  appearance: none;
  background: map.get(variables.$colours, "grey-light-500");
  border: 1px solid map.get(variables.$colours, "grey-bold-900");
  border-radius: map.get(variables.$border-radii, x-small);
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: variables.$base-line-height * 0.5;
  width: 100%;
}
input[type="file"] {
    padding: .55em .75em;
}

footer,
section {
  margin-top: variables.$base-line-height * 3;

  & + section {
    margin-top: variables.$base-line-height * 1.5;
  }
}

select {
  appearance: none;
  border: 1px solid map.get(variables.$colours, "grey-bold-900");
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
  background-color: map.get(variables.$colours, "grey-light-500");
  background-position-x: calc(100% - #{variables.$base-line-height * 0.25});
  background-position-y: variables.$base-line-height * 0.4;
  border-radius: map.get(variables.$border-radii, x-small);
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  padding: variables.$base-line-height * 0.5;
  padding-right: variables.$base-line-height * 1.5;
  line-height: inherit;
  width: 100%;
}

ol,
ul {
  margin: 0 0 0 variables.$base-line-height;
  padding: 0;

  &:where([role="list"]) {
    list-style: none;
    margin: 0;

    & > li {
      margin: 0;
      padding: 0;
    }
  }
}

p {
  margin: 0;
}

.break-anywhere {
  line-break: anywhere;
}

.form-input-error {
  color: map-get(variables.$colours, red-medium-500);
  font-size: percentage(math.div(14px, variables.$base-font-size));
  font-weight: 600;
}
