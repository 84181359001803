@use "../functions";
@use "../variables";

.search-form {
  align-items: center;
  display: flex;

  label {
    margin-right: variables.$base-line-height * 0.5;
    font-weight: bold;
    white-space: nowrap;
  }

  input[type="search"],
  select {
    border-right: none;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  input[type="submit"] {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
