@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.pill {
  font-size: percentage(math.div(13px, variables.$base-font-size));
  background: map.get(variables.$colours, "grey-subtle-500");
  padding: (variables.$base-line-height * 0.125) (variables.$base-line-height * 0.5);
  border-radius: variables.$base-line-height * 0.5;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: functions.px-to-rem(0.5px);

  &--eol {
    background: lightcoral;
  }

  &--shipped {
    background: lightgreen;
  }

  &--processing {
    background: lightgoldenrodyellow;
  }
}
