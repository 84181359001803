@use "sass:map";
@use "../variables";

.myae-log-in {
  background: url("undefined/../../images/login-background.png") no-repeat center center;
  background-color: map.get(variables.$colours, "blue-darkest-900");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}
