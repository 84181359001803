@use "sass:map";
@use "../variables";

.feedback-message {
  background-color: map.get(variables.$colours, "green-medium-500");
  padding: variables.$base-line-height;
  margin: 0 0 (variables.$base-line-height * 1.5);

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  &.response-error {
    background: rgba(map-get(variables.$colours, "orange-100"), 0.5);
  }

  a {
    color: inherit;
  }
}
