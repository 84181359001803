@use "sass:map";
@use "../variables";

.skip-links {
  align-content: center;
  background: map.get(variables.$colours, "white");
  box-sizing: border-box;
  height: auto;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 2;

  &:focus-within {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.125s ease-in;
  }
}
