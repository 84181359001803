.directory-notification-tree {
  list-style: none;
  margin-left: -1.5em;

  label + & {
    margin-left: 0;
  }

  li {
    margin: 10px 0 10px 1.5em;
  }
}
