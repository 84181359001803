@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.myae-logo {
  align-items: center;
  display: flex;
  gap: variables.$base-line-height * 0.5;

  * + * {
    font-size: percentage(math.div(32px, variables.$base-font-size));
    font-weight: bold;
    line-height: 1;
    order: 2;
  }

  &::before {
    background: map.get(variables.$colours, "grey-bold-500");
    content: "";
    display: block;
    height: functions.px-to-rem(56px);
    order: 1;
    width: functions.px-to-rem(2px);
  }

  p {
    margin: 0;
  }
}
