@use "sass:map";
@use "../functions";
@use "../variables";

.download-cta {
  --cta-base-colour: #{map.get(variables.$colours, "blue-darkest-500")};
  --cta-secondary-colour: #{map.get(variables.$colours, "white")};

  position: relative;
  padding: variables.$base-line-height * 0.5;
  border: functions.px-to-rem(1px) solid var(--cta-base-colour);
  border-left-width: variables.$base-line-height * 2;
  border-radius: variables.$base-line-height * 0.5;

  svg {
    color: var(--cta-secondary-colour);
    width: variables.$base-line-height;
    height: variables.$base-line-height;
    position: absolute;
    left: variables.$base-line-height * -1.5;
    top: variables.$base-line-height * 0.5;
  }

  &:focus-within, &:hover {
    --cta-base-colour: #{map.get(variables.$colours, "blue-darkest-900")};
  }

  a {
    display: block;
    text-decoration: none;

    &::before {
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  ul {
    display: flex;
    gap: variables.$base-line-height;
  }
}
