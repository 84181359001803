@use "sass:map";
@use "../variables";
@use "../functions";

.tooltip {
  display: flex;
  gap: variables.$base-line-height * 0.5;
  justify-content: center;
  position: relative;

  &:before {
    content: attr(data-text);
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    left:100%;
    margin-left: variables.$base-line-height * 0.5;
    width: max-content;
    overflow-wrap: anywhere;
    max-width: 300px;
    padding: variables.$base-line-height * 0.5;
    border-radius: 1.5rem;
    background: map.get(variables.$colours, blue-darkest-900);
    color: map.get(variables.$colours, grey-light-500);
    text-align:center;
    display:none;
  }

  &:hover:before {
    display:block;
  }
}
