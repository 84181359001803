@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.rohs-table {
  $s: &;

  &__footer {
    background-color: map.get(variables.$colours, "grey-subtle-500");
    border-bottom-left-radius: variables.$base-line-height;
    border-bottom-right-radius: variables.$base-line-height;
    display: flex;
    gap: variables.$base-line-height * 0.5;
    justify-content: flex-start;
    padding: (variables.$base-line-height * 0.75) variables.$base-line-height;
  }

  &__table {
    border: functions.px-to-rem(1px) solid map-get(variables.$colours, "grey-subtle-500");
    border-collapse: separate;
    border-spacing: 0;
    border-top-left-radius: variables.$base-line-height * 0.5;
    border-top-right-radius: variables.$base-line-height * 0.5;
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    td, th {
      padding: variables.$base-line-height * 0.25;

      &.icon {
        font-size: 200%;
        text-align: center;
      }
    }

    th {
      background-color: map.get(variables.$colours, "grey-subtle-500");
      font-size: percentage(math.div(14px, variables.$base-font-size));
    }

    thead th[colspan="6"] {
      border-bottom: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-900");
      padding-left: 0;
    }

    thead th[rowspan] {
      padding-right: 0;
    }

    tr {
      & > *:first-child:not(.suppress-padding) {
        padding-left: variables.$base-line-height;
      }

      & > *:last-child {
        padding-right: variables.$base-line-height;
      }
    }

    .row-heading {
      font-weight: bold;
    }
  }
}
