.product-change-notification-table {
  .data-table__table {
    table-layout: auto;

    th,
    td {
      padding: .375rem 0.75em;
      white-space: nowrap;

      &.placeholder-column {
        width: 100%;
      }
    }
  }
}
