@use "sass:map";
@use "../functions";
@use "../variables";

.action-group {
  max-width: 60ch;
  margin: 0 auto;
  position: relative;
  padding: (variables.$base-line-height * 0.5);
  border: functions.px-to-rem(2px) solid map.get(variables.$colours, "grey-subtle-500");
  border-radius: variables.$base-line-height * 0.5;

  .myae-button {
    width: 100%;
  }
}
