@use "sass:map";
@use "../functions";
@use "../variables";

.profile-button {
  background: map.get(variables.$colours, "grey-light-500") !important;
  box-sizing: border-box;
  border: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-500") !important;
  border-radius: map.get(variables.$border-radii, small);
  display: inline-block;
  font-family: inherit;
  padding:
    (variables.$base-line-height * 0.5)
    variables.$base-line-height
    (variables.$base-line-height * 0.5)
    (variables.$base-line-height * 2.5) !important;
  position: relative;
  height: functions.px-to-rem(50px);

  &::before {
    content: attr(data-initials);
    background: map.get(variables.$colours, "grey-subtle-500");
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
}
