@use "sass:map";
@use "../functions";
@use "../variables";

.styled-checkbox,
.form-input-checkbox {
  input {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;

    & + label {
      display: block;
      margin-left: 0 !important;
      padding: 0 0 0 functions.px-to-rem(40px);
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        height: 24px;
        width: 24px;
        background: map.get(variables.$colours, "grey-light-500");
        border: 1px solid map.get(variables.$colours, "grey-bold-900");
        border-radius: map.get(variables.$border-radii, x-small);
        position: absolute;
        left: 0;
        box-sizing: border-box;
      }
    }

    &:checked + label {
      &::before {
        background-image: url("undefined/../../images/check.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 85%;
      }
    }

    &:focus + label {
      &::before {
        outline: none;
        box-shadow: 0 0 0 2px lightblue;
        box-shadow: 0 0 0 2px Highlight;
        box-shadow: 0 0 0 2px -webkit-focus-ring-color;
      }
    }
  }
}
