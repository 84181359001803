@import "../variables";

.eol-filter {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 1rem;

    input, select {
        background: map-get($colours, "white");
        border: 1px solid map-get($colours, "grey-bold-900");
        box-sizing: border-box;
        height: 2rem;
        padding: 0 1rem;
        font-weight: bold;
        border-radius: 1rem;
        max-width: 16rem;
        width: 100%;
    }
    select {
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 94%;
        background-position-y: 50%;
        margin: 0 1rem;
    }
    button, a {
        //height: 36px;
        font-size: 14px;
        line-height: 0;
        margin: 0;
    }
    a {
        padding: 1rem;
        margin-left: 1rem;
    }
}

.eol-table {
    border-width: 0 1px;
    margin-bottom: 2rem;
    border-collapse: collapse;
    //table-layout: auto;

  .col-sm {
    min-width: 150px;
    max-width: 160px;
    //width: 15%;
  }
  .col-md {
    max-width: 200px;
    min-width: 175px;
  }
  .col-grow {
    min-width: 175px;
    width: 100%;
  }

    th,
    td {
        border: 0;
        padding: ($base-line-height * 0.5);
    }

    thead {
        tr {
            border: 1px solid map-get($colours, "grey-subtle-500");

            &:first-child {
                border: none;
                border-radius: 1rem;
                th {
                    box-shadow: -1px 0 map-get($colours, "grey-subtle-500"); //this is a nasty fix for border/border collapse issue
                    background: map-get($colours, "grey-subtle-500");
                    &:first-child {
                        border-top-left-radius: 2rem;
                    }
                    &:last-child{
                        border-top-right-radius: 2rem;
                    }
                }
            }
        }

        tr th {
            text-align: left;
            //min-width: 110px;
          &.button-row {
            text-align: right;
          }
        }
    }

    tbody {

        tr {
            border: 1px solid map-get($colours, "grey-subtle-500");

            &:nth-child(odd) {
                > td {
                  background: #f7f7f7;
                }
            }
        }
    }

    tfoot {
        tr {
            td {
              box-shadow: -1px 0 map-get($colours, "grey-subtle-500"); //this is a nasty fix for border/border collapse issue
              background-color: map-get($colours, "grey-subtle-500");
              text-align: center;
              border-bottom-left-radius: 2rem;
              border-bottom-right-radius: 2rem;
            }
        }
    }
}
