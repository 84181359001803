@use "../variables";

footer {
  padding-top: 4.5rem;
  margin-top: auto;
}

.myae-site-footer {
  padding: variables.$base-line-height 0;

  p {
    margin: 0 !important;
  }
}
