@use "sass:map";
@use "../variables";

.pagination {
  display: flex;
  overflow: hidden;

  &__item {
    background-color: map.get(variables.$colours, "grey-light-500");
    border: 1px solid map.get(variables.$colours, "grey-bold-900");
    padding: (variables.$base-line-height * 0.25) (variables.$base-line-height * 0.5) !important;
    white-space: nowrap;

    &--current {
      background-color: map.get(variables.$colours, "grey-bold-900");
      color: map.get(variables.$colours, "white") !important;
    }

    &:first-child {
      border-bottom-left-radius: map.get(variables.$border-radii, x-small);
      border-top-left-radius: map.get(variables.$border-radii, x-small);
    }

    &:last-child {
      border-bottom-right-radius: map.get(variables.$border-radii, x-small);
      border-top-right-radius: map.get(variables.$border-radii, x-small);
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
