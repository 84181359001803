@use "sass:map";
@use "../functions";
@use "../variables";

.myae-masthead {
  background: url("undefined/../../images/login-background.png") no-repeat center right;
  background-color: map.get(variables.$colours, "blue-darkest-900");
  background-size: 65%;
  color: map.get(variables.$colours, "white");
  height: variables.$base-line-height * 6;
  padding: (variables.$base-line-height * 2) 0;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
  }

  h1 {
    margin: 0;
  }

  &__breadcrumbs {
    margin-top: variables.$base-line-height * 0.5;

    ol {
      display: flex;

      a {
        color: inherit;
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      li:not(:first-child) {
        padding-left: variables.$base-line-height * 0.75;
        position: relative;
        margin-left: variables.$base-line-height * 0.75;

        &::before {
          background: map.get(variables.$colours, "blue-darkest-500");
          content: "";
          display: block;
          height: calc(100% + #{variables.$base-line-height * 0.25});
          left: functions.px-to-rem(-1px);
          position: absolute;
          top: variables.$base-line-height * -0.125;
          transform: skewX(-20deg);
          width: functions.px-to-rem(2px);
        }
      }
    }
  }
}
