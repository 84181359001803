@import "../functions";
@import "../variables";

.order-history-options {
  background-color: map-get($colours, "grey-subtle-500");
  border-top-left-radius: $base-line-height;
  border-top-right-radius: $base-line-height;
  display: flex;
  justify-content: space-between;
  padding: ($base-line-height * 0.75) $base-line-height;

  &__date-filter {
    flex-grow: 1;
    align-items: center;
    display: flex;
    gap: $base-line-height * 0.5;

    label {
      font-weight: bold;
      white-space: nowrap;
    }

    select {
      line-height: $base-line-height;
      width: 100%;
      max-width: 300px;
      padding: $base-line-height * 0.25;
      background-position-y: $base-line-height * 0.25;
    }
  }

  &__add-sample {
    flex-grow: 1;

    form {
      align-items: center;
      display: flex;

      input[type="text"] {
        border-bottom-right-radius: 0;
        border-right: none;
        border-top-right-radius: 0;
        line-height: $base-line-height;
        max-width: 400px;
        padding: $base-line-height * 0.25;
      }

      label {
        font-weight: bold;
        margin-right: $base-line-height * 0.5;
        white-space: nowrap;
      }

      .myae-button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &__find-order {
    align-items: center;
    display: flex;

    input[type="text"] {
      border-bottom-right-radius: 0;
      border-right: none;
      border-top-right-radius: 0;
      line-height: $base-line-height;
      max-width: 400px;
      padding: $base-line-height * 0.25;
    }

    label {
      font-weight: bold;
      margin-right: $base-line-height * 0.5;
      white-space: nowrap;
    }

    .myae-button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  & + .order-history-table {
    border-top-color: map-get($colours, "grey-subtle-900");
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &--reversed {
    border-radius: 0 0 $base-line-height $base-line-height;
    border-top: px-to-rem(1px) solid map-get($colours, "grey-subtle-900");
  }
}
