@use "../functions";
@use "../variables";

.filter-form {
  align-items: center;
  display: flex;


  label {
    margin-right: variables.$base-line-height * 0.5;
    font-weight: bold;
    white-space: nowrap;
  }

  input[type="search"],
  input[type="submit"],
  select {
    border-radius: 1.5rem;
    margin-right: variables.$base-line-height * 0.5;
  }
}
