@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.myae-site-header {
  padding: variables.$base-line-height 0;

  &--condensed {
    padding: (variables.$base-line-height * 0.5) 0;
    border-bottom: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-500");
    text-align: right;
    font-size: percentage(math.div(14px, variables.$base-font-size));

    a {
      text-decoration: none;
      font-weight: bold;
      color: inherit;

      &::before {
        background: currentColor;
        content: "";
        display: inline-block;
        height: 0.75rem;
        -webkit-mask: url("undefined/../../images/exit.svg");
        mask: url("undefined/../../images/exit.svg");
        -webkit-mask-position: left center;
        mask-position: left center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        pointer-events: none;
        width: variables.$base-line-height * 0.75;
        vertical-align: middle;
      }
    }
  }
}
