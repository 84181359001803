@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.autocomplete {
  background-color: white;
  border: functions.px-to-rem(1px) solid map-get(variables.$colours, grey-bold-500);
  border-radius: variables.$base-line-height * 0.5;
  >div {
    padding: (variables.$base-line-height * 0.5);
  }
  .selected {
    background-color: map-get(variables.$colours, grey-subtle-500);
    border-radius: variables.$base-line-height * 0.5;
  }
}
