@use "sass:map";
@use "../variables";

.nag-banner {
  $s: &;

  &--active {
    #{$s}__banner {
      padding-bottom: variables.$base-line-height * 2;
    }

    #{$s}__overlay {
      opacity: 0.5;
    }
  }

  &__banner {
    background: map.get(variables.$colours, "blue-darkest-900");
    bottom: 0;
    color: map.get(variables.$colours, "white");
    padding: variables.$base-line-height;
    position: fixed;
    transition: padding-bottom 0.25s;
    width: 100%;

    &__container {

    }
  }

  &__overlay {
    background: map.get(variables.$colours, "blue-darkest-900");
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    pointer-events: none;
    right: 0;
    top: 0;
    transition: opacity 0.25s;
  }
}
