@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.shortcut-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: variables.$base-line-height;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 920px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__item {
    height: variables.$base-line-height * 8;
    position: relative;

    a {
      align-items: flex-end;
      background: linear-gradient(to right, #172b40, #111126);
      border-radius: map.get(variables.$border-radii, small);
      box-sizing: border-box;
      color: map.get(variables.$colours, "white");
      display: flex;
      font-size: percentage(math.div(24px, variables.$base-font-size));
      height: 100%;
      line-height: variables.$base-line-height * 1.25;
      padding: variables.$base-line-height * 1;
      position: absolute;
      text-decoration: none;
      top: 0;
      width: 100%;

      &::after {
        background: url("undefined/../../images/triangle-right.svg") no-repeat center right;
        bottom: variables.$base-line-height;
        content: "";
        display: block;
        height: 1.8rem;
        position: absolute;
        right: variables.$base-line-height;
        vertical-align: bottom;
        width: 1.5rem;
      }

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    button {
      border: none;
      position: absolute;
      right: variables.$base-line-height * 0.5;
      top: variables.$base-line-height * 0.5;
      visibility: hidden;
    }

    &:hover,
    &:focus-within {
      button {
        visibility: visible;
      }
    }
  }

  &--empty {
    align-content: center;
    border: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-500");
    border-radius: map.get(variables.$border-radii, small);
    display: grid;
    height: variables.$base-line-height * 8;
    grid-template-columns: 1fr;
    text-align: center;

    p {
      display: flex;
      align-items: center;
      gap: variables.$base-line-height * 0.5;
      justify-content: center;
    }
  }
}
