.modal-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);

  &.closed {
    display: none;
  }

  .modal {
    display: flex;
    margin: auto;
    padding: 1.5rem 2.25rem;
    border: .0625rem solid #ddd;
    border-radius: 2rem;
    background: white;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;

    >p {
      text-align: center;
      margin-bottom: 1rem;
    }

    >h3 {
      text-align: center;
    }

    span.close-modal {
      float: right;
      font-size: 2rem;
      font-weight: bold;
      cursor: pointer;
      position: absolute;
    }

    .modal-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px 10px;
    }
  }
}
