@import "visually-hidden";
@import "../variables";

.mydocuments-search-wrap--full-width {
    box-sizing: border-box;
    padding: 0 1.5rem;
    max-width: $content-width;
    width: 100%;
    margin: 4.5rem auto 0;

    #main {
        @media screen and (min-width: 746px) {
            display: flex;
        }

        .rc-docs {
            display: flex;
            max-width: 400px;

            input {
                background: map-get($colours, "white") !important;
                border: 1px solid map-get($colours, "grey-bold-900");
                box-sizing: border-box;
                height: 36px !important;
                padding: 16px !important;
                margin: 0 !important;
                font-weight: bold;
                border-right: 0;
                border-radius: 24px 0 0 24px;
                width: 100% !important;
                max-width: 260px !important;
            }

            button {
                height: 36px;
                font-size: 14px;
                line-height: 0;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                margin: 0 0 0 -10px;
            }
        }

        #content {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            margin-bottom: 0 !important;

            .documents-browser-header {
                background: map-get($colours, "grey-subtle-500");
                border-bottom: 1px solid #c9c9c9;
                border-top-left-radius: 24px;
                border-top-right-radius: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 16px 16px 24px !important;
                gap: $base-line-height;

                @media screen and (max-width: 1000px) {
                    flex-direction: column;
                }
            }

            .breadcrumbs {
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                gap: 0.75rem;

                li {
                    margin: 0;
                    padding: 0;

                    & + li {
                        position: relative;
                        padding-left: 0.75rem;

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            height: 100%;
                            width: 2px;
                            background: map-get($colours, "grey-bold-500");
                            transform: skewX(-12.5deg);
                        }
                    }

                    &:last-child {
                        a {
                            font-weight: bold;
                            max-width: 50ch !important;
                        }
                    }

                    a {
                        white-space: pre;
                        max-width: 15ch;
                        text-overflow: ellipsis;
                        display: block;
                        overflow: hidden;
                        font-weight: normal;
                        text-decoration: none;
                        color: map-get($colours, "blue-darkest-900");

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .header-actions {
                display: flex;
                align-items: center;
                gap: $base-line-height;

                .directory-action {
                    display: flex;
                    color: #172b40;
                    font-size: 14px;
                    text-decoration: none;
                    white-space: nowrap;

                    &:hover {
                        text-decoration: underline;
                    }

                    &::before {
                        content: "";
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 36px;
                    }

                    &.add-file::before {
                        background-image: url("undefined/../../images/file-plus.svg");
                    }

                    &.add-link::before {
                        background-image: url("undefined/../../images/link-plus.svg");
                    }
                }
            }

            .table-container {
                overflow-x: auto;

                .directory-table {
                    border: solid #dbdbdb;
                    border-width: 0 1px;
                    border-collapse: collapse;
                    width: 100%;

                    th,
                    td {
                        border: 0;
                        padding: ($base-line-height * 0.5);

                        &:not(.wrap-text) {
                          white-space: nowrap;
                        }
                    }

                    thead {
                        background: map-get($colours, "grey-subtle-500");

                        tr th {
                            text-align: left;
                            min-width: 110px;
                        }
                    }

                    tbody {
                        tr {
                            border-bottom: 1px solid #dbdbdb;

                            &:nth-child(odd) {
                                background: #f7f7f7;
                            }

                            td {
                                &,
                                a:not(.myae-button) {
                                    color: black;
                                    text-decoration: none;
                                }

                                a:hover {
                                    text-decoration: underline;
                                }

                                .file-name-icon {
                                    display: flex;
                                    gap: ($base-line-height * 0.5);

                                    .file-icon {
                                        height: 20px;
                                        width: 20px;
                                        margin: auto 0;
                                        object-fit: cover;
                                    }
                                }

                                .myae-button {
                                    padding: ($base-line-height * 0.25) ($base-line-height * 0.5);
                                    font-size: 14px;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }

            .directory-empty {
                border: solid #dbdbdb;
                border-width: 0 1px 1px;
                text-align: center;
                margin: 0;
                padding: ($base-line-height * 0.5);
            }
        }
    }
}
