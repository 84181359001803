@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.log-in-widget {
  background: map.get(variables.$colours, "white");
  border-radius: map.get(variables.$border-radii, large);
  padding: variables.$base-line-height * 1.5;

  &__footer {
    text-align: center;
    padding-top: variables.$base-line-height * 1.5;
    margin-top: variables.$base-line-height * 1.5;
    margin-left: variables.$base-line-height * -1.5;
    margin-right: variables.$base-line-height * -1.5;
    border-top: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-500");

    p {
      margin: 0;
    }
  }

  &__header {
    text-align: center;

    h1,
    p {
      margin: (variables.$base-line-height * 0.5) 0 0;
    }
  }

  &__form {
    margin: (variables.$base-line-height * 1.5) 0 0;

    &__item {
      * + *,
      & + #password-validation-container {
        margin-top: variables.$base-line-height * 0.25;
      }

      & + &,
      #password-validation-container + & {
        margin-top: variables.$base-line-height;
      }

      &.right-aligned-text {
        margin-top: variables.$base-line-height;
        font-size: percentage(math.div(14px, variables.$base-font-size));
      }
    }
  }

  .feedback-message {
    margin: (variables.$base-line-height * 1.5) (variables.$base-line-height * -1.5) 0;
    padding-left: variables.$base-line-height * 1.5;
    padding-right: variables.$base-line-height * 1.5;

    & + .feedback-message {
      margin-top: 0;
    }

  }

  &.log-in-notice {
    margin: 0 0 auto 20px;
    text-align: center;

    h2,
    p {
      margin: 0 0 5px;
    }
  }
}
