@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.feature-table {
  $s: &;

  &__table {
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    td, th {
      padding: (variables.$base-line-height * 0.25) (variables.$base-line-height * 0.5);
    }

    tr + tr td {
      border-top: functions.px-to-rem(1px) solid map-get(variables.$colours, "grey-subtle-500");
    }

    tr {
      & > *:first-child {
        padding-left: 0;
      }

      & > *:last-child {
        padding-right: 0;
      }
    }
  }
}
