@use "../variables";

// Based on the repel utility class from
// <https://set.studio/reality-check-1-building-out-a-furniture-site-from-dribbble/>.

.repel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: variables.$base-line-height;

  &--vertical {
    align-items: stretch;
    flex-direction: column;
  }
}
