@use "../variables";

.hug {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: variables.$base-line-height;

  &--end {
    justify-content: flex-end;
  }

  &--start {
    justify-content: flex-start;
  }
}
