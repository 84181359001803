@use "../variables";

.stack {
  & > * + * {
    margin-top: variables.$base-line-height * 0.5;
  }

  & > .stack__wide + *,
  & > * + .stack__wide {
    margin-top: variables.$base-line-height;
  }

  & > .stack__widest + *,
  & > * + .stack__widest {
    margin-top: variables.$base-line-height * 2;
  }

  & > .form-input + .form-input,
  & > #password-validation-container + .form-input {
    margin-top: variables.$base-line-height * 0.75;
  }

  & > * + h2,
  & > * + h3, {
    margin-top: variables.$base-line-height * 1.5;
  }
}
