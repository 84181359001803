@use "sass:map";
@use "../functions";
@use "../variables";

.tabbed-list {
  border: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-500");
  border-radius: map.get(variables.$border-radii, medium);
  display: flex;
  overflow: hidden;

  &__tabs {
    background: map.get(variables.$colours, "grey-light-500");
    border-right: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-500");
    padding: variables.$base-line-height (variables.$base-line-height * 1.5);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a {
      display: block;
      padding: (variables.$base-line-height * 0.5) (variables.$base-line-height * 1.5);
      margin: 0 (variables.$base-line-height * -1.5);
      text-decoration: none;
      color: inherit;
      white-space: pre;

      &[aria-selected="true"] {
        background: map.get(variables.$colours, "grey-subtle-500");
      }
    }
  }

  &__items {
    color: black;
    flex-grow: 1;
    padding: variables.$base-line-height * 1.5;
    display: grid;
    display: -ms-grid;

    & > * {
      display: flex;
      flex-direction: column;
      grid-area: 1 / -1;

      .actions {
        margin-top: auto;
        margin-bottom: 0;
      }

      & > *:first-child {
        margin-top: 0;
      }
    }

    [hidden] {
      display: block;
      visibility: hidden;
    }

  }

  &:not(.tabbed-list--initialised) {
    .tabbed-list__tabs li:first-child a {
      background: map.get(variables.$colours, "grey-subtle-500");
    }

    & > .tabbed-list__items > *:not(:first-child) {
      display: block;
      visibility: hidden;
    }
  }
}
