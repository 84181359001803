@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.data-table {
  $s: &;

  > :first-child {
    border-top-left-radius: variables.$base-line-height;
    border-top-right-radius: variables.$base-line-height;  }

  > :last-child {
    border-bottom-left-radius: variables.$base-line-height;
    border-bottom-right-radius: variables.$base-line-height;
  }

  &__actions {
    background-color: map.get(variables.$colours, "grey-subtle-500");
    display: flex;
    gap: variables.$base-line-height * 0.5;
    justify-content: flex-start;
    padding: (variables.$base-line-height * 0.75) variables.$base-line-height;

    &__section {
      align-items: center;
      display: flex;
      gap: variables.$base-line-height * 0.5;
      width: 100%;

      &--centered {
        justify-content: center;
      }

      &--right-aligned {
        justify-content: flex-end;
      }
    }

    &--spread {
      justify-content: space-between;
    }
  }

  &__table-container {
    overflow-x: auto;
  }

  &__table {
    border: functions.px-to-rem(1px) solid map-get(variables.$colours, "grey-subtle-500");
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    td, th {
      padding: variables.$base-line-height * 0.25;
    }

    th {
      background-color: map.get(variables.$colours, "grey-subtle-500");
      font-size: percentage(math.div(14px, variables.$base-font-size));

      .sort {
        color: inherit;
        text-decoration: none;
        white-space: nowrap;

        &::before,
        &::after {
          background: currentColor;
          content: "";
          display: inline-block;
          height: variables.$base-line-height * 0.75;
          -webkit-mask: url("undefined/../../images/sort.svg");
          mask: url("undefined/../../images/sort.svg");
          -webkit-mask-position: center center;
          mask-position: center center;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-size: 50%;
          mask-size: 50%;
          opacity: 0.25;
          vertical-align: middle;
          width: variables.$base-line-height * 0.75;
        }

        &::before {
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          opacity: 1;
        }

        &:focus,
        &:hover {
          text-decoration: underline;
        }

        &--ascending {
          position: relative;

          &::before {
            display: block;
            -webkit-mask-image: url("undefined/../../images/sort-up.svg");
            mask-image: url("undefined/../../images/sort-up.svg");
          }
        }

        &--descending {
          position: relative;

          &::before {
            display: block;
            -webkit-mask-image: url("undefined/../../images/sort-down.svg");
            mask-image: url("undefined/../../images/sort-down.svg");
          }
        }
      }
    }

    tr {
      & > *:first-child {
        padding-left: variables.$base-line-height;
      }

      & > *:last-child {
        padding-right: variables.$base-line-height;
      }

      & > td.no-results-found {
        column-span: all;
        padding: variables.$base-line-height;
      }
    }
  }

  select {
    background-position-y: 50%;
    line-height: inherit;
    padding-bottom: variables.$base-line-height * 0.25;
    padding-top: variables.$base-line-height * 0.25;
    padding-left: variables.$base-line-height * 0.5;
    width: auto;
  }

  input[type="search"] {
    padding-top: variables.$base-line-height * 0.25;
    padding-bottom: variables.$base-line-height * 0.25;
  }

  .myae-button {
    font-size: percentage(math.div(14px, variables.$base-font-size));
    padding: (variables.$base-line-height * 0.25) (variables.$base-line-height * 0.75);
  }
}
