@use "sass:map";
@use "../variables";
@import "@splidejs/splide/dist/css/splide.min.css";
@import "modal";

.image-gallery {
  max-width: 400px;
  #main-carousel {
    cursor: zoom-in;
  }
  &__image {
    aspect-ratio: 3 / 2;
    position: relative;
    width: 100%;

    svg {
      bottom: variables.$base-line-height;
      height: variables.$base-line-height * 1.5;
      position: absolute;
      right: variables.$base-line-height;
      width: variables.$base-line-height * 1.5;
    }
  }

  &__thumbnails {
    display: flex;
    gap: variables.$base-line-height * 0.5;
    margin: (variables.$base-line-height * 0.5) 0 0;
    overflow-x: scroll;

    & > * {
      background: map.get(variables.$colours, "grey-subtle-500");
      position: relative;
      height: variables.$base-line-height * 3;
    }
  }
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border-color: map.get(variables.$colours, "grey-subtle-900");
  border-width: 2px;
  border-radius: 3px;
}
.splide__slide {
  > img {
    width: 100%;
    height: auto;
  }
}

.modal-container {
  .gallery-modal {
    @extend .modal;
  }
}
