@use "../variables";

.content {
  box-sizing: border-box;
  max-width: variables.$content-width;
  margin-left: auto;
  margin-right: auto;
  padding: 0 variables.$base-line-height;
  width: 100%;

  &--two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &--with-sidebar {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: variables.$base-font-size * 1.5;
  }

  &--smaller {
    max-width: variables.$content-width-smaller;
  }

  &--smallest {
    max-width: variables.$content-width-smallest;
  }
}
