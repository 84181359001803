@use "sass:math";
@use "functions";

$base-font-size: 16px;
$base-line-height: functions.px-to-rem(24px);
$base-one-em-in-pixels: 16px;
$base-one-pixel-in-em: math.div(1px, $base-one-em-in-pixels) * 1em;
$base-one-pixel-in-rem: math.div(1px, $base-one-em-in-pixels) * 1rem;

$breakpoints: (
  x-small: 320px,
  small: 480px,
  medium: 768px,
  large: 1024px,
  x-large: 1140px,
);

// The following design tokens are based on the Advanced Energy branding
// guidelines and the "High Fidelity Designs" Figma document.

$colours: (
  // According to the Sass compiler, CSS colour names should be quoted
  // when using as map keys to safeguard against producing invalid CSS.
  "black": #000000,
  "white": #ffffff,
  "blue-darkest-500": #2d6aa8,
  "blue-darkest-900": #04101b,
  "green-medium-500": #7ab837,
  "grey-light-500": #f5f5f5,
  "grey-subtle-100": #eeeeee,
  "grey-subtle-500": #dddddd,
  "grey-subtle-900": #c9c9c9,
  "grey-bold-100": #a1abb2,
  "grey-bold-500": #768591,
  "grey-bold-900": #53575a,
  "orange-100": #ff7540,
  "orange-500": #fe5000,
  "orange-900": #da5037,
  "red-medium-100": #f04937,
  "red-medium-500": #d53318,
  "red-medium-900": #b82927,
);

$border-radii: (
  x-small: functions.px-to-rem(8px),
  small: functions.px-to-rem(28px),
  medium: functions.px-to-rem(32px),
  large: functions.px-to-rem(56px),
);

$content-width: functions.px-to-rem(1250px);
$content-width-smaller: functions.px-to-rem(1080px);
$content-width-smallest: functions.px-to-rem(720px);

$font-families: (
  base: 'Arial, sans-serif',
);
