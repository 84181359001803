@use "sass:map";
@use "../functions";
@use "../variables";

.logo-and-navigation {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__navigation-list {
    align-items: center;
    display: flex;
    gap: variables.$base-line-height;
    list-style: none;
    margin: 0;
    padding: 0;

    & > * {
      margin: 0;
      padding: 0;
    }
  }

  a {
    color: inherit;
    font-weight: 700;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }

  }

  .has-children {
    position: relative;

    button,
    span, {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent !important;
      border: inherit;
      color: inherit;
      cursor: pointer;
      font-family: inherit;
      font-size: inherit;
      font-weight: 700;
      text-decoration: none;
      margin: 0;
      padding: 0;

      &[aria-expanded="true"],
      &:focus,
      &:hover {
        background: inherit;
        text-decoration: underline;
      }

      &::after {
        background: currentColor;
        content: "";
        display: inline-block;
        height: 1rem;
        margin-left: 0.125rem;
        -webkit-mask: url("undefined/../../images/chevron-down.svg");
        mask: url("undefined/../../images/chevron-down.svg");
        -webkit-mask-position: right center;
        mask-position: right center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: variables.$base-line-height * 0.7;
        mask-size: variables.$base-line-height * 0.7;
        pointer-events: none;
        width: variables.$base-line-height;
        vertical-align: middle;
      }
    }

    ul {
      background: map.get(variables.$colours, "white");
      border: functions.px-to-rem(1px) solid map.get(variables.$colours, "grey-subtle-500");
      border-radius: map.get(variables.$border-radii, x-small);
      left: variables.$base-line-height * -1;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      opacity: 0;
      top: calc(100% + #{variables.$base-line-height});
      visibility: hidden;

      &[aria-hidden="false"] {
        opacity: 1;
        transition: opacity 0.125s ease;
        visibility: visible;
      }

      li {
        padding: (variables.$base-line-height * 0.5) variables.$base-line-height;

        & + li {
          padding-top: 0;
        }

        a {
          white-space: pre;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }

    &--has-profile-button {
      ul {
        left: auto;
        right: 0;
        top: calc(100% + #{variables.$base-line-height * 0.5});
      }
    }
  }
}
