@use "sass:map";
@use "sass:math";
@use "../functions";
@use "../variables";

.myae-button {
  background: linear-gradient(to right, #172b40, #111126) !important;
  box-sizing: border-box;
  color: map.get(variables.$colours, "white");
  border: functions.px-to-rem(1px) solid transparent;
  border-radius: map.get(variables.$border-radii, small);
  display: inline-block;
  font-family: inherit;
  font-size: 100%;
  font-weight: bold;
  line-height: variables.$base-line-height;
  padding: (variables.$base-line-height * 0.5) variables.$base-line-height;
  text-align: center;
  text-decoration: none;

  &:focus,
  &:hover {
    color: map.get(variables.$colours, "white");
    text-decoration: underline;
    cursor: pointer;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--invert {
    background: map.get(variables.$colours, "white") !important;
    border-color: map.get(variables.$colours, "blue-darkest-500") !important;
    color: map.get(variables.$colours, "blue-darkest-500");

    &:focus,
    &:hover {
      color: map.get(variables.$colours, "blue-darkest-500");
    }
  }

  &--larger {
    font-size: percentage(math.div(20px, 16px));
    padding: functions.px-to-rem(16px) functions.px-to-rem(24px);
  }

  &--right-arrow {
    &::after {
      background: url("undefined/../../images/triangle-right.svg") no-repeat center right;
      content: "";
      display: inline-block;
      height: 1.5rem;
      vertical-align: bottom;
      width: 1.5rem;
    }
  }

  &--remove {
    height: variables.$base-line-height * 2;
    padding: 0;
    position: relative;
    width: variables.$base-line-height * 2;

    &::before {
      background: currentColor;
      content: "";
      display: block;
      left: 0;
      height: 100%;
      -webkit-mask: url("undefined/../../images/trash.svg") center no-repeat;
      mask: url("undefined/../../images/trash.svg") center no-repeat;
      -webkit-mask-size: 60%;
      mask-size: 60%;
      top: 0;
      width: 100%;
    }
  }

  &--smaller {
    font-size: percentage(math.div(14px, variables.$base-font-size));
    padding: (variables.$base-line-height * 0.25) (variables.$base-line-height * 0.75);
  }
}
